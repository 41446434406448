import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { Product } from 'dw-uk-common';
import standardiseContentfulId from '../../util/standardiseContentfulId';

const propTypes = {
  data: PropTypes.object.isRequired,
};

/**
 * Premiere wines is a wrapper for the premiere wines component
 * @param {object} data The data from contentful containing a contentful id and product id
 * @returns
 */
const PremiereWines = ({ data }) => {
  const { productId, contentfulId } = data;

  return (
    <Container
      data-testid="premiere-wines"
      className="premiere-wines my-5"
      id={standardiseContentfulId(contentfulId)}
    >
      <Product.ProductListing products={productId} />
    </Container>
  );
};

PremiereWines.propTypes = propTypes;

export { PremiereWines };
