import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { Product } from 'dw-uk-common';
import standardiseContentfulId from '../../util/standardiseContentfulId';

const propTypes = {
  brand: PropTypes.string,
  data: PropTypes.object.isRequired,
};

const defaultProps = {
  brand: 'law',
};

/**
 * Beyond The Label is a wrapper for the beyond the label component
 * @param {object} data The data from contentful containing a contentful id and product id 
 */
const BeyondTheLabel = ({ brand, data }) => {
  const { productId, contentfulId } = data;

  return (
    <Container
      data-testid="beyond-the-label"
      className={`${brand} beyond-the-label my-5`}
      id={standardiseContentfulId(contentfulId)}
    >
      <Product.BeyondTheLabel id={productId[0]} />
    </Container>
  );
};

BeyondTheLabel.propTypes = propTypes;
BeyondTheLabel.defaultProps = defaultProps;

export { BeyondTheLabel };
