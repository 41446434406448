/**
 * Standard Content Block is used for creating a full width component that
 * sits within our content container, has an optional background to apply
 * and contains text, a heading and an optional image.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { Container } from 'react-bootstrap';
import { StandardContentBlock, YoutubePlayer } from 'dw-contentful-components';
import { RichText } from '../RichText';
import { MediaWrapper } from '../MediaWrapper';
import standardiseContentfulId from '../../util/standardiseContentfulId';

const props = {
  brand: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

/**
 * Standard Content Block
 * Wraps the component library Standard Content Block providing rich text
 *
 * @param {string} brand The brand the content block should be styled for
 * @param {object} data Contains the data for the content block such as the
 * contentful entry id, heading, content and media coming from contentful, as well as
 * booleans for whether the image is displayed to the left or right and for the background
 */
const StandardContentBlockWrapper = ({ brand, data }) => {
  const { contentfulId, heading, content, media, isMediaLeft, hasBackground } =
    data;
  const { accessibilityText: alt, asset, videoId } = media || {};

  const headingRichTextOptions = {
    renderMark: {
      [MARKS.BOLD]: (text) => <strong>{text}</strong>,
      [MARKS.ITALIC]: (text) => <i>{text}</i>,
      [MARKS.UNDERLINE]: (text) => <u>{text}</u>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <h2>{children}</h2>,
    },
  };

  const backgroundStyling = hasBackground ? 'bg-shadow' : '';

  const headingElement = heading ? (
    <RichText content={heading} options={headingRichTextOptions} />
  ) : null;

  const contentElement = <RichText content={content} />;

  const renderMedia = () => {
    if (!media) return null;

    if (videoId) {
      return <YoutubePlayer videoId={videoId} />;
    }

    return <MediaWrapper brand={brand} asset={asset} altText={alt} />;
  };

  return (
    <section
      data-testid="content-block"
      className={`${brand} ${backgroundStyling} py-5 content-block-section`}
      id={standardiseContentfulId(contentfulId)}
    >
      <Container>
        <StandardContentBlock
          heading={headingElement}
          content={contentElement}
          brand={brand}
          isMediaLeft={isMediaLeft}
          hasEmbeddedVideo={!!videoId}
        >
          {renderMedia()}
        </StandardContentBlock>
      </Container>
    </section>
  );
};

StandardContentBlockWrapper.propTypes = props;

export { StandardContentBlockWrapper };
