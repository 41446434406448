/**
 * InfoSection is the component responsible for building out the InfoBlock's and ButtonWithContent
 * sections in thec pages.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { InfoBlock, YoutubePlayer } from 'dw-contentful-components';
import { Container, Row, Col } from 'react-bootstrap';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { RichText } from '../RichText';
import { MediaWrapper } from '../MediaWrapper';
import { ButtonWithContentWrapper } from '../ButtonWithContentWrapper';

const props = {
  brand: PropTypes.string.isRequired,
  infoSection: PropTypes.object.isRequired,
};
/**
 * InfoSection is responsible for mapping through the InfoBlock's and placing either
 * an InfoBlock or a ButtonWithContent in the column.
 * @param  {} brand
 * @param  {} infoSection
 */
const InfoSection = ({ brand, infoSection }) => {
  const { infoBlocks, title } = infoSection;

  const infoTitleRichTextOptions = {
    renderMark: {
      [MARKS.BOLD]: (richText) => <strong>{richText}</strong>,
      [MARKS.ITALIC]: (richText) => <i>{richText}</i>,
      [MARKS.UNDERLINE]: (richText) => <u>{richText}</u>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="mb-0">{children}</p>
      ),
    },
  };

  const infoTextRichTextOptions = {
    renderMark: {
      [MARKS.BOLD]: (richText) => <strong>{richText}</strong>,
      [MARKS.ITALIC]: (richText) => <i>{richText}</i>,
      [MARKS.UNDERLINE]: (richText) => <u>{richText}</u>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      [BLOCKS.OL_LIST]: (node, children) => {
        return (
          <ol>
            {children.map((child) => {
              return child;
            })}
          </ol>
        );
      },
      [BLOCKS.UL_LIST]: (node, children) => {
        return (
          <ul>
            {children.map((child) => {
              return child;
            })}
          </ul>
        );
      },
    },
  };

  const renderBlockType = (block) => {
    const blockType = block?.__typename;

    switch (blockType) {
      case 'ContentfulInfoBlock': {
        const {
          media,
          title: infoTitleContent,
          content: infoTextContent,
        } = block;

        const { accessibilityText: alt, asset, videoId } = media || {};

        const infoTitle = (
          <RichText
            content={infoTitleContent}
            options={infoTitleRichTextOptions}
          />
        );

        const infoText = (
          <RichText
            content={infoTextContent}
            options={infoTextRichTextOptions}
          />
        );

        return (
          <InfoBlock
            brand={brand}
            title={infoTitle}
            text={infoText}
            hasEmbeddedVideo={!!videoId}
          >
            {videoId ? (
              <YoutubePlayer videoId={videoId} />
            ) : (
              <MediaWrapper brand={brand} asset={asset} altText={alt} />
            )}
          </InfoBlock>
        );
      }
      case 'ContentfulButtonWithContent': {
        return (
          <ButtonWithContentWrapper
            brand={brand}
            data={{ ...block, hasShadow: true, card: true }}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div data-testid="info-section-wrapper">
      <Container className="pt-5">
        {title && (
          <Row className="mx-0 mb-5">
            <h2 className="info-section-title">{title}</h2>
          </Row>
        )}
        <Row className="ml-md-0 ">
          {infoBlocks &&
            infoBlocks?.map((block) => (
              <Col
                md={4}
                sm={12}
                key={block.id}
                data-testid="info-block-data"
                className="pl-md-0 pb-4"
              >
                {renderBlockType(block)}
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  );
};

InfoSection.propTypes = props;

export { InfoSection };
