/**
 * MediaWrapper is a GatsbyImage wrapper, it is responsible for
 * providing the props to MediaComponent and retrieving the image
 * with the Gastby API's 'getImage()'
 */

import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Media } from 'dw-contentful-components';

const props = {
  altText: PropTypes.string,
  caption: PropTypes.string,
  brand: PropTypes.string.isRequired,
  asset: PropTypes.object,
};

const defaultProps = {
  altText: '',
  caption: '',
  asset: null,
};

/**
 * Builds the MediaComponent with a GatsbyImage
 * @param  {String} altText
 * @param  {String} caption
 * @param  {JSON} asset - A JSON Object containing the gatsbyImageData
 * @param  {String} brand - Which brand is being used for style
 */
const MediaWrapper = ({ altText, caption, asset, brand }) => {
  const image = asset ? getImage(asset) : false;

  return (
    image && (
      <div data-testid="media-wrapper">
        <Media alt={altText} caption={caption} brand={brand}>
          <GatsbyImage image={image} alt={altText} />
        </Media>
      </div>
    )
  );
};

MediaWrapper.propTypes = props;
MediaWrapper.defaultProps = defaultProps;

export { MediaWrapper };
