/**
 * The Section component conditionally renders a contentful component depending on the typename
 */
import React from 'react';
import PropTypes from 'prop-types';
import { HeroBannerWrapper } from '../HeroBannerWrapper';
import { ReferenceCardSection } from '../ReferenceCardSection';
import { InfoSection } from '../InfoSection';
import { ButtonWithContentWrapper } from '../ButtonWithContentWrapper';
import { BackToTop } from '../BackToTop';
import { PromoStripWrapper } from '../PromoStripWrapper';
import { StandardContentBlockWrapper } from '../StandardContentBlockWrapper';
import { ProductListing } from '../ProductListing';
import { BeyondTheLabel } from '../BeyondTheLabel';
import { PremiereWines } from '../PremiereWines';

const props = {
  brand: PropTypes.string,
  data: PropTypes.object,
  backButton: PropTypes.bool,
};

const defaultProps = {
  brand: 'law',
  data: {},
  backButton: false,
};

/**
 * Section component
 * @param {string} brand The brand to style the component for.
 * @param {object} data The contentful data to be used for the component.
 * @param {boolean} backButton Whether the back button is shown or not on the ref card section
 */
const Section = ({ brand, data, backButton }) => {
  const sectionType = data?.__typename;

  switch (sectionType) {
    case 'ContentfulBanner': {
      return <HeroBannerWrapper brand={brand} heroBanner={data} />;
    }
    case 'ContentfulReferenceCardSection': {
      return (
        <ReferenceCardSection
          brand={brand}
          data={data}
          backButton={!!backButton}
        />
      );
    }
    case 'ContentfulInfoBlockSection': {
      return <InfoSection brand={brand} infoSection={data} />;
    }
    case 'ContentfulButtonWithContent': {
      return <ButtonWithContentWrapper brand={brand} data={data} />;
    }
    case 'ContentfulLink': {
      return <BackToTop />;
    }
    case 'ContentfulPromoStrip': {
      return <PromoStripWrapper brand={brand} data={data} />;
    }
    case 'ContentfulStandardContentBlock': {
      return <StandardContentBlockWrapper brand={brand} data={data} />;
    }
    case 'ContentfulProduct': {
      switch (data.type) {
        case 'Beyond the label': {
          return <BeyondTheLabel brand={brand} data={data} />;
        }
        case 'Add to basket': {
          return <ProductListing data={data} />;
        }
        case 'Premiere Wines': {
          return <PremiereWines data={data} />;
        }
        default: {
          return null;
        }
      }
    }
    default:
      return null;
  }
};

Section.propTypes = props;
Section.defaultProps = defaultProps;

export { Section };
