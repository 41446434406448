// Produces a button next to a divider to take you to the top of the page once clicked
import React from 'react';
import { Container } from 'react-bootstrap';
import { ChevronButtonWithText } from 'dw-contentful-components';
import '../../styles/BackToTop.scss';

/**
 * Back To Top is a chevron button with text that when clicked scrolls the user to the top of the page
 */
const BackToTop = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Container data-testid="back-to-top" className="back-to-top">
      <div className="h-100 d-flex">
        <div className="flex-grow-1 pr-4 align-self-center">
          <hr className="w-100" />
        </div>
        <div>
          <ChevronButtonWithText
            chevronDirection="up"
            text="Back to Top"
            onClick={scrollToTop}
          />
        </div>
      </div>
    </Container>
  );
};

export { BackToTop };
