/**
 * Promo Strip Wrapper wraps the promo strip to provide the promo strip component with the correct props
 */
import React from 'react';
import PropTypes from 'prop-types';
import { PromoStrip } from 'dw-contentful-components';
import { RichText } from '../RichText';
import '../../styles/PromoStripWrapper.scss';

const props = {
  brand: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

/**
 * Promo Strip Wrapper
 * @param {string} brand The brand to style this component for 
 * @param {object} data Contains the data from contentful such as the text and background colour
 */
const PromoStripWrapper = ({ brand, data }) => {
  const { backgroundColour, contentText, link } = data;
  const url = link?.url;

  const content = <RichText content={contentText} />;

  return (
    <div
      data-testid="promo-strip-wrapper"
      className={`${brand} promo-strip-wrapper`}
    >
      <PromoStrip
        content={content}
        url={url}
        backgroundColour={backgroundColour}
      />
    </div>
  );
};

PromoStripWrapper.propTypes = props;

export { PromoStripWrapper };
