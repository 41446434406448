import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { Product } from 'dw-uk-common';
import standardiseContentfulId from '../../util/standardiseContentfulId';

const propTypes = {
  data: PropTypes.object.isRequired,
};

/**
 * Product Listing is a wrapper for the product listing component
 * @param {object} data The data from contentful containing a contentful id and product id 
 * @returns 
 */
const ProductListing = ({ data }) => {
  const { productId, contentfulId } = data;
  
  return (
    <Container
      data-testid="product-listing"
      className="product-listing mt-5"
      id={standardiseContentfulId(contentfulId)}
    >
      <Product.ProductListing id={productId[0]}/>
    </Container>
  );
};

ProductListing.propTypes = propTypes;

export { ProductListing };
